.App {
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack header and table vertically */
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 20px;

  .header {
    background-color: black;
    background-image: url('./Icons/header-bg.svg'); /* Icons by SVGBackgrounds.com */
    background-size: cover;
    background-repeat: no-repeat; /* Prevents the background from repeating */
    background-position: center; /* Centers the SVG */
    color: white;
    padding: 50px 20px; /* Padding for top/bottom and sides */
    text-align: center;
    position: relative;
    min-height: 256px;
    z-index: 1; /* Stays on top of other content */
  }

  .main-content {
    border-radius: 2px;
    position: relative;
    z-index: 2;
    background-color: white;
    margin-top: -150px; /* Negative margin to overlap header */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    width: 1000px;
    padding-bottom: 20px;
    align-self: center;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 600px) {
    .main-content {
      width: auto;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
