.adb-tab {
  position: absolute;
  top: 25px;
  left: -15px;
  z-index: 3;
  width: 100px;

  svg {
    width: 22px;
    height: 22px;
    fill: #000;
    background-color: white;
  }

  .MuiAvatar-root {
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}
